/* to-do: investigate why this is needed */
body {
    background-color: #f0f2f5;
}

.tac {
    text-align: center;
}

.tar {
    text-align: right;
}

.mb-1 {
    margin-bottom: 16px;
}

.mb-2 {
    margin-bottom: 32px;
}

.p-2 {
    padding: 32px;
}

.header-layout{
    background-color: white;
}

.section-card{
    height: 140px;
}

.canvas{
    max-width: 1024px;
    width: 100%;
    max-height: 576px;
    height: auto;
}

.card-title{
    color: green;
    font-weight: 550;
    font-size: 16px;
}

.width-input{
    width: 100%;
}

.mt-1{
    margin-top: 40px;
}

.mt-2{
    margin-top: 100px;
}

