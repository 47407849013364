.thumbnails {
    flex-wrap: nowrap;
    overflow-x: auto;
}

.thumbnail {
    width: 256px;
    height: 144px;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    border-style: solid;
    border-color: red;
    border-width: 0;
    position: relative;
}

.thumbnail.selected {
    border-width: 2px;
}

.thumbnail-caption {
    position: absolute;
    width: 100%;
    height: 32px;
    bottom: 0;
    text-align: right;
    padding: 0 16px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    line-height: 32px;
}

.thumbnail-caption.processed {
    background-color: rgba(0, 255, 0, 0.5);
}

.info-card{
    height: 100px;
}

.footer{
    text-align: center;
}

@media screen and (max-width: 800px){
    .thumbnail {
        width: 128px;
        height: 72px;
    }

    .thumbnail-caption {
        height: 16px;
        padding: 0 8px;
        line-height: 16px;
        font-size: 9px;
    }
}
